import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Aux from '../../hoc/_Aux/_Aux';
import resumePath from '../../assets/images/DanBeltramo_Resume_2025.pdf';

const about = props => {
	return (
		<Aux>
			<Helmet>
				<title>About</title>
				<meta name="description" content="Dan Beltramo - About Me" />
			</Helmet>
<div className="home-background-color">
						<div className="animated introList l-w1 delay">
			<h1 className="pullquote home-light-color m-t-60 about-text">
			I'm a UX/UI designer based in Portland, Oregon, currently shaping the visual experience of the Google Pixel as part of the Android visual design team. With a rich background in architecture and over nine years in design, I excel at transforming complex user needs into intuitive and lasting solutions. My approach integrates my architectural expertise with a commitment to creating inclusive and effective user experiences.	
			<br/><br/>
			In my role, I leverage my strong visual design skills and meticulous attention to detail to strip back and refine products, enhancing their value through thoughtful reduction. Collaborative and driven, I thrive in team environments where I can contribute to the creation of groundbreaking products. For me, the essence of good design lies in what you choose to leave out, ensuring every element serves a purpose.</h1>
<div class="resume">
					<a href={resumePath} target="_blank" rel="noopener noreferrer">
					<button className="m-blackText m-b-60 home-surface-color home-special-color">
						Resume
					</button>
					</a>
				</div>
			</div>
</div>
		</Aux>
	);
};

export default about;
