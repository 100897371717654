import React, { Component } from "react";
import Aux from "../../../hoc/_Aux/_Aux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ImageWithDesc from "../../Images/ImageWithDesc";
import { ebayData } from "../../../data/app_data";

class Ebay extends Component {
  render() {

    let ebayImage1 = ebayData.slice(0, 1).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ebayImage2 = ebayData.slice(1, 2).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ebayImage3 = ebayData.slice(2, 3).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ebayImage4 = ebayData.slice(3, 4).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ebayImage5 = ebayData.slice(4, 5).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ebayImage6 = ebayData.slice(5, 6).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ebayImage7 = ebayData.slice(6, 7).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ebayImage8 = ebayData.slice(7, 8).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ebayImage9 = ebayData.slice(8, 9).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ebayImage10 = ebayData.slice(9, 10).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ebayImage11 = ebayData.slice(10, 11).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    return (
      <Aux>
        <Helmet>
          <title>BLT - eBay</title>
          <meta name="description" content="Ebay Selling Design" />
          <html className="ebay-background-color"/>
        </Helmet>
        <div className="ebay-background-color">

          <div className="l-w1 m-projCopy animated">
            <h1 className="m-quote topper ebay-lighttext-color">
              eBay selling
            </h1>
          </div>
          <div className="l-w1 l-flexbox-alldevs projTitle">
            <div className="introList animated delay3">
            <li className="abcd ebay-surface-color ebay-button-color">2020</li>
            <li className="abcd ebay-surface-color ebay-button-color hidemobile">Contract</li>
              <li className="abcd ebay-surface-color ebay-button-color hidemobile">E-commerce</li>
              <li className="abcd ebay-surface-color ebay-button-color">UX UI</li>
              <li className="abcd ebay-surface-color ebay-button-color">E2E</li>
            </div>
          </div>
          <div className="l-w1 m-projCopy animated delay">
              <p className="m-projCopy-top ebay-lighttext-color pad-Bot-60 ">
Within Selling Design, I documented E2E flows, revealed seams, refined dashboards, designed portions of the new x-platform listing experience, and built a multi-flow listing prototype for UXR. As well, I partnered closely with PM and UXR teammates, and gathered actionable insights from sitting in on UXR sessions.   <br/><br/> In documenting E2E onboarding journeys, I studied eBay's and competitors' journeys  and identified seams within our experience.           </p>
          </div>
          <div className="l-w1 animated delay ">
          {ebayImage1}</div>
          <div className="l-w1 animated delay ">
          {ebayImage2}
          </div>
          <div className="l-w1 animated delay ">
          {ebayImage3}
          {ebayImage11}
          </div>
          <h1 className="pullquote m-projTitle l-w2 ebay-lighttext-color">
          To capture the E2E experience of subscribing to a store, I sketched this user flow diagram, documented existing conditions, and documented seams.
          </h1>
          <div className="l-w1 animated delay ">
          {ebayImage4}
          </div>
          <div className="l-w1 animated delay ">
          {ebayImage5}
          </div>

          <div className="l-w1 animated delay ">
          {ebayImage6}
          </div>
          <h1 className="pullquote  l-w2 ebay-lighttext-color">
        Terapeak: In cataloging the process of subscribing to Terapeak’s research insights, I built out this user flow diagram, documented existing conditions, and noted seams.
          </h1>
          <div className="l-w1 animated delay ">
          {ebayImage7}
          </div>
          <div className="l-w1 animated delay ">
          {ebayImage8}
          </div>
          <h1 className="pullquote  l-w2 ebay-lighttext-color">
          Selling  Preferences: I partnered with an adjacent team to refactor and rebuild this outdated Preferences page, to embody a simplified and clarified experience.
          </h1>
          <div className="l-w1 animated delay ">
          {ebayImage9}
          </div>
          <div className="l-w1 animated delay ">
          {ebayImage10}
          </div>

          <div className="l-w0 m-mobileProjLink">
            <Link to="/gatorade">
              <button className="projectDetails no-background ebay-surface-color m-projbutton">
                <span className="button-text ebay-light-color ebay-button-color">
                  Next Project
                </span>{" "}
                <span className="material-icons ebay-light-color ebay-button-color">
                  arrow_forward
                </span>
              </button>
            </Link>
          </div>
        </div>
      </Aux>
    );
  }
}

export default Ebay;
